import React from 'react'

const ShowPasswordIcon = ({ show }) => {
  return (
    <div style={{ display: 'flex' }}>
      {show ? (
        <svg
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9999 14.33C8.60992 14.33 6.66992 12.39 6.66992 9.99998C6.66992 7.60998 8.60992 5.66998 10.9999 5.66998C13.3899 5.66998 15.3299 7.60998 15.3299 9.99998C15.3299 12.39 13.3899 14.33 10.9999 14.33ZM10.9999 7.16998C9.43992 7.16998 8.16992 8.43998 8.16992 9.99998C8.16992 11.56 9.43992 12.83 10.9999 12.83C12.5599 12.83 13.8299 11.56 13.8299 9.99998C13.8299 8.43998 12.5599 7.16998 10.9999 7.16998Z"
            fill="#898989"
          />
          <path
            d="M11.0001 19.02C7.24008 19.02 3.69008 16.82 1.25008 13C0.190078 11.35 0.190078 8.65998 1.25008 6.99998C3.70008 3.17998 7.25008 0.97998 11.0001 0.97998C14.7501 0.97998 18.3001 3.17998 20.7401 6.99998C21.8001 8.64998 21.8001 11.34 20.7401 13C18.3001 16.82 14.7501 19.02 11.0001 19.02ZM11.0001 2.47998C7.77008 2.47998 4.68008 4.41998 2.52008 7.80998C1.77008 8.97998 1.77008 11.02 2.52008 12.19C4.68008 15.58 7.77008 17.52 11.0001 17.52C14.2301 17.52 17.3201 15.58 19.4801 12.19C20.2301 11.02 20.2301 8.97998 19.4801 7.80998C17.3201 4.41998 14.2301 2.47998 11.0001 2.47998Z"
            fill="#898989"
          />
        </svg>
      ) : (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.2175 14.0275C8.0275 14.0275 7.8375 13.9575 7.6875 13.8075C6.8675 12.9875 6.4175 11.8975 6.4175 10.7475C6.4175 8.3575 8.3575 6.4175 10.7475 6.4175C11.8975 6.4175 12.9875 6.8675 13.8075 7.6875C13.9475 7.8275 14.0275 8.0175 14.0275 8.2175C14.0275 8.4175 13.9475 8.6075 13.8075 8.7475L8.7475 13.8075C8.5975 13.9575 8.4075 14.0275 8.2175 14.0275ZM10.7475 7.9175C9.1875 7.9175 7.9175 9.1875 7.9175 10.7475C7.9175 11.2475 8.0475 11.7275 8.2875 12.1475L12.1475 8.2875C11.7275 8.0475 11.2475 7.9175 10.7475 7.9175Z"
            fill="#898989"
          />
          <path
            d="M4.3475 17.2575C4.1775 17.2575 3.9975 17.1975 3.8575 17.0775C2.7875 16.1675 1.8275 15.0475 1.0075 13.7475C-0.0525 12.0975 -0.0525 9.4075 1.0075 7.7475C3.4475 3.9275 6.9975 1.7275 10.7475 1.7275C12.9475 1.7275 15.1175 2.4875 17.0175 3.9175C17.3475 4.1675 17.4175 4.6375 17.1675 4.9675C16.9175 5.2975 16.4475 5.3675 16.1175 5.1175C14.4775 3.8775 12.6175 3.2275 10.7475 3.2275C7.5175 3.2275 4.4275 5.1675 2.2675 8.5575C1.5175 9.7275 1.5175 11.7675 2.2675 12.9375C3.0175 14.1075 3.8775 15.1175 4.8275 15.9375C5.1375 16.2075 5.1775 16.6775 4.9075 16.9975C4.7675 17.1675 4.5575 17.2575 4.3475 17.2575Z"
            fill="#898989"
          />
          <path
            d="M10.7475 19.7675C9.4175 19.7675 8.1175 19.4975 6.8675 18.9675C6.4875 18.8075 6.3075 18.3675 6.4675 17.9875C6.6275 17.6075 7.0675 17.4275 7.4475 17.5875C8.5075 18.0375 9.6175 18.2675 10.7375 18.2675C13.9675 18.2675 17.0575 16.3275 19.2175 12.9375C19.9675 11.7675 19.9675 9.7275 19.2175 8.5575C18.9075 8.0675 18.5675 7.5975 18.2075 7.1575C17.9475 6.8375 17.9975 6.3675 18.3175 6.0975C18.6375 5.8375 19.1075 5.8775 19.3775 6.2075C19.7675 6.6875 20.1475 7.2075 20.4875 7.7475C21.5475 9.3975 21.5475 12.0875 20.4875 13.7475C18.0475 17.5675 14.4975 19.7675 10.7475 19.7675Z"
            fill="#898989"
          />
          <path
            d="M11.4375 15.0175C11.0875 15.0175 10.7675 14.7675 10.6975 14.4075C10.6175 13.9975 10.8875 13.6075 11.2975 13.5375C12.3975 13.3375 13.3175 12.4175 13.5175 11.3175C13.5975 10.9075 13.9875 10.6475 14.3975 10.7175C14.8075 10.7975 15.0775 11.1875 14.9975 11.5975C14.6775 13.3275 13.2975 14.6975 11.5775 15.0175C11.5275 15.0075 11.4875 15.0175 11.4375 15.0175Z"
            fill="#898989"
          />
          <path
            d="M0.7475 21.4975C0.5575 21.4975 0.3675 21.4275 0.2175 21.2775C-0.0725 20.9875 -0.0725 20.5075 0.2175 20.2175L7.6875 12.7475C7.9775 12.4575 8.4575 12.4575 8.7475 12.7475C9.0375 13.0375 9.0375 13.5175 8.7475 13.8075L1.2775 21.2775C1.1275 21.4275 0.9375 21.4975 0.7475 21.4975Z"
            fill="#898989"
          />
          <path
            d="M13.2775 8.9675C13.0875 8.9675 12.8975 8.8975 12.7475 8.7475C12.4575 8.4575 12.4575 7.9775 12.7475 7.6875L20.2175 0.2175C20.5075 -0.0725 20.9875 -0.0725 21.2775 0.2175C21.5675 0.5075 21.5675 0.9875 21.2775 1.2775L13.8075 8.7475C13.6575 8.8975 13.4675 8.9675 13.2775 8.9675Z"
            fill="#898989"
          />
        </svg>
      )}
    </div>
  )
}

export default ShowPasswordIcon
