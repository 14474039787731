import setProduct from './action'
import axios from 'axios'

export const BASE_URL = process.env.REACT_APP_API_URL

export const getFrameById =
  (
    id,
    checkedProducts,
    payMethodId,
    productsParams,
    productsSign,
    preOrderId
  ) =>
  async (dispatch) => {
    try {
      let requestUrl = `${BASE_URL}/frames/${id}`

      if (payMethodId) {
        requestUrl += `?pm_id=${payMethodId}`
      }

      if (checkedProducts) {
        requestUrl += `${payMethodId ? '&' : '?'}${checkedProducts}`
      }

      if (productsParams) {
        requestUrl += `${payMethodId || checkedProducts ? '&' : '?'}products=${productsParams}`
      }

      if (productsSign) {
        requestUrl += `${payMethodId || checkedProducts || productsParams ? '&' : '?'}products_sign=${productsSign}`
      }

      if (preOrderId) {
        requestUrl += `${payMethodId || checkedProducts || productsParams || productsSign ? '&' : '?'}pre_order_id=${preOrderId}`
      }

      const response = await axios.get(requestUrl)
      dispatch(setProduct(response.data))
    } catch (error) {
      console.error(error)
    }
  }
