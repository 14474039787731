import styles from './FormHeader.module.scss'
import React from 'react'

function FormHeader({ finsovetnik, bindingProduct }) {
  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'

  return (
    <>
      {isIpOrAck ? null : finsovetnik ? (
        <img src="/FSlogo.png" alt="" className={styles.FSlogo} />
      ) : (
        <img src="/logo.png" alt="" className={styles.logo} />
      )}
      {!bindingProduct ? (
        <div className={styles.frameHeader}>Оформление заказа</div>
      ) : (
        <div className={styles.frameHeader}>Привязка карты</div>
      )}
    </>
  )
}

export default FormHeader
