import { object, string } from 'yup'

export const InitialValues = {
  acc_type: 'email',
  code: '',
  login: '',
  pass: ''
}

export const SigninSchema = object({
  acc_type: string(),
  code: string().required('Login is required').min(6),
  login: string().required('Login is required'),
  pass: string().required('Login is required')
})
