import styles from './Button.module.scss'
import React from 'react'

const Button = ({
  type,
  width,
  backgroundColor,
  borderColor,
  color,
  children,
  onClick,
  isDisabled
}) => {
  const style =
    width || backgroundColor || borderColor || color
      ? { style: { width, backgroundColor, borderColor, color } }
      : {}

  const isPrimary = type === 'primary'

  return (
    <button
      className={
        isPrimary ? styles.button : `${styles.button} ${styles.secondary}`
      }
      onClick={onClick}
      type="button"
      disabled={isDisabled}
      {...style}
    >
      {children}
    </button>
  )
}

export default Button
