import Typography from '../components/Typography/Typography'
import styles from './RegistrationFourthStep.module.scss'
import React, { useState, useEffect } from 'react'
import ReactCodeInput from 'react-code-input'

const RegistrationFourthStep = ({ formik, size }) => {
  const [counter, setCounter] = useState(59)

  useEffect(() => {
    const timer = counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  return (
    <div className={styles.container}>
      <ReactCodeInput
        className={styles['code-input-container']}
        fields={size}
        name="code"
        inputMode="numeric"
        value={formik.values.code}
        onChange={(e) => {
          formik.setFieldValue('code', e)
        }}
      />
      {counter > 0 && (
        <div className={styles.timer}>{`00:${`0${counter}`.slice(-2)}`}</div>
      )}
      <div
        className={
          counter > 0
            ? `${styles.disabled} ${styles['code-send-again']}`
            : styles['code-send-again']
        }
        onClick={() => {
          if (counter === 0) {
            setCounter(59)
            formik.handleSubmit()
          }
        }}
      >
        <Typography color="#4285F4">Отправить код повторно</Typography>
      </div>
    </div>
  )
}

export default RegistrationFourthStep
