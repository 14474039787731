import Promocode from '../../../Promocode/Promocode'
import React, { useEffect, useState } from 'react'
import styles from './PromoBalance.module.scss'
import Balance from '../../../Balance/Balance'

function PromoBalance({
  result,
  userBalance,
  promoRes,
  setPromoRes,
  checkedProducts,
  promocode,
  setPromocode,
  setPromocode_id,
  setPromoEr,
  payment_method_id,
  oldPrice,
  productAmount,
  ukey,
  balance_reserve_id,
  setBalance_reserve_id,
  setBalance_summ,
  cloud
}) {
  const [promo, setPromo] = useState(true)
  const [balance, setBalance] = useState(false)

  const promocode_status = result?.promocode_status
  const balance_status = result?.balance_status || null

  useEffect(() => {
    if (userBalance) {
      setPromo(false)
      setBalance(true)
    }
  }, [userBalance])

  function changeSale() {
    setPromo(!promo)
    setBalance(!balance)
  }

  return (
    <>
      {promocode_status && balance_status && userBalance && (
        <div className={styles.promo_balance}>
          <input
            type="radio"
            name="sale"
            id="balance"
            checked={balance}
            value={balance}
            onChange={changeSale}
            disabled={promoRes.success}
          />
          <label className={styles.saleLabel} htmlFor="balance">
            Использовать баланс pro.finansy
          </label>

          <input
            type="radio"
            name="sale"
            id="promo"
            value={promo}
            checked={promo}
            onChange={changeSale}
            disabled={promoRes.success}
          />
          <label className={styles.saleLabel} htmlFor="promo">
            Использовать промокод
          </label>
        </div>
      )}
      {promocode_status && balance_status && promo && (
        <Promocode
          promoRes={promoRes}
          setPromoRes={setPromoRes}
          checkedProducts={checkedProducts}
          promocode={promocode}
          setPromocode={setPromocode}
          setPromocode_id={setPromocode_id}
          setPromoEr={setPromoEr}
          payment_method_id={payment_method_id}
        />
      )}

      {balance_status && promocode_status && balance && (
        <Balance
          promoRes={promoRes}
          setPromoRes={setPromoRes}
          checkedProducts={checkedProducts}
          userBalance={userBalance}
          oldPrice={oldPrice}
          productAmount={productAmount}
          ukey={ukey}
          balance_reserve_id={balance_reserve_id}
          setBalance_reserve_id={setBalance_reserve_id}
          setBalance_summ={setBalance_summ}
          payment_method_id={payment_method_id}
          cloud={cloud}
        />
      )}

      {promocode_status && !balance_status && (
        <Promocode
          promoRes={promoRes}
          setPromoRes={setPromoRes}
          checkedProducts={checkedProducts}
          promocode={promocode}
          setPromocode={setPromocode}
          setPromocode_id={setPromocode_id}
          setPromoEr={setPromoEr}
          payment_method_id={payment_method_id}
        />
      )}

      {balance_status && !promocode_status && userBalance && (
        <Balance
          promoRes={promoRes}
          setPromoRes={setPromoRes}
          checkedProducts={checkedProducts}
          userBalance={userBalance}
          oldPrice={oldPrice}
          productAmount={productAmount}
          ukey={ukey}
          balance_reserve_id={balance_reserve_id}
          setBalance_reserve_id={setBalance_reserve_id}
          setBalance_summ={setBalance_summ}
          payment_method_id={payment_method_id}
          cloud={cloud}
        />
      )}
    </>
  )
}

export default PromoBalance
