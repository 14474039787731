import { composeWithDevTools } from '@redux-devtools/extension'
import { applyMiddleware, createStore } from 'redux'
import rootReducer from './reducers/rootReducer'
import { initialState } from './initialState'
import thunk from 'redux-thunk'

const store = createStore(
  rootReducer,
  initialState(),
  composeWithDevTools(applyMiddleware(thunk))
)

export default store
