import styles from './FormDisabled.module.scss'
import React from 'react'

function FormDisabled({ error, disabled }) {
  const responseErrors = [
    'Products required',
    'Error sign',
    'Error chunk',
    'Error product_id',
    'Error quantity',
    'Error price',
    'Error potok'
  ]

  const paramsError = responseErrors.includes(error)

  const orderError = error === 'Order already paid'

  const handleRedirect = () => {
    window.open('https://profinansy.ru/course-store/cart', '_self')
  }

  const frameNotFound = error === 'Frame not found'

  return (
    <div className={styles.disabled}>
      <div className={styles.text}>
        {disabled || paramsError || frameNotFound
          ? 'Форма оплаты недоступна...'
          : 'Мы уже обрабатываем данный заказ. Подождите, пожалуйста. Статус заказа скоро обновится.'}
      </div>
      {(paramsError || orderError) && (
        <button
          type="button"
          className={styles.button}
          onClick={handleRedirect}
        >
          Вернуться в магазин
        </button>
      )}
    </div>
  )
}

export default FormDisabled
