import { getCurrencySymbol } from '../../../../utils/getCurrencySymbol'
import { declOfNum } from '../../../../utils/declOfNum'
import styles from './ProductsAndPrice.module.scss'
import React, { useMemo, useState } from 'react'

function ProductsAndPrice({
  price,
  countSalesStatus,
  bindingProduct,
  products,
  promoRes,
  currency,
  oldPrice
}) {
  const [showProd, setShowProd] = useState(false)

  const salePrice = useMemo(() => {
    return promoRes?.products?.reduce((sum, el) => sum + el.price_difference, 0)
  }, [promoRes])

  return (
    <>
      {!countSalesStatus && !bindingProduct && (
        <div className={styles.productsTitle}>
          <div className={styles.prodAmount}>
            {products?.length === 1 ? 'Выбран' : 'Выбрано'}{' '}
            {products?.length || 0}{' '}
            {declOfNum(products?.length, ['продукт', 'продукта', 'продуктов'])}
          </div>
          <div
            className={styles.showProdBtn}
            onClick={() => setShowProd(!showProd)}
          >
            {showProd ? 'Скрыть продукты' : 'Показать продукты'}
          </div>
        </div>
      )}
      {!countSalesStatus && promoRes.success
        ? showProd &&
          promoRes.products?.map((elem, i) => (
            <div className={styles.productsRow} key={i}>
              <div className={styles.frameProducts}>
                <div className={styles.number}>{i + 1}.</div>
                <div>
                  <div className={styles.frameProductTitle}>{elem.name}</div>
                  <div className={styles.frameProductDescription}>
                    {elem.description}
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.frameProdPrice}>
                  {elem.new_price < 0
                    ? 0
                    : elem.new_price?.toLocaleString('ru-RU')}{' '}
                  {getCurrencySymbol(currency)}
                </div>
                {elem.price_difference !== 0 && (
                  <div className={styles.frameOldPrice}>
                    <div className={styles.oldPrice}>
                      {elem.price?.toLocaleString('ru-RU')}{' '}
                      {getCurrencySymbol(currency)}
                    </div>
                    {promoRes.discount_percent_status ? (
                      <div
                        className={styles.priceDifference}
                      >{`-${promoRes.discount}%`}</div>
                    ) : (
                      <div className={styles.priceDifference}>{`-${
                        elem.price_difference
                      } ${getCurrencySymbol(currency)}`}</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))
        : showProd &&
          products?.map((el, i) => (
            <div className={styles.productsRow} key={i}>
              <div className={styles.frameProducts}>
                <div className={styles.number}>{i + 1}.</div>
                <div>
                  <div className={styles.frameProductTitle}>
                    {el.name === null
                      ? el.product_info.custom_name === null
                        ? el.product_info.name
                        : el.product_info.custom_name
                      : el.name}
                  </div>
                  <div className={styles.frameProductDescription}>
                    {el.description === null
                      ? el.product_info.description
                      : el.description}
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.frameProdPrice}>
                  {(el.price === null
                    ? el.product_info.price
                    : el.price
                  )?.toLocaleString('ru-RU')}{' '}
                  {getCurrencySymbol(currency)}
                </div>
              </div>
            </div>
          ))}

      {!bindingProduct ? (
        <div className={styles.line} />
      ) : (
        <div className={styles.emptyLine} />
      )}

      <div className={styles.priceRow}>
        {!countSalesStatus && (
          <div className={styles.price}>
            <span className={styles.sum}>Итого:</span>
            <span className={styles.sumPrice}>
              {price?.toLocaleString('ru-RU')}{' '}
              {getCurrencySymbol(currency) || '₽'}
            </span>
          </div>
        )}
        {!countSalesStatus && promoRes.success && (
          <div className={styles.sumPromo}>
            <div className={styles.oldSum}>
              <div className={styles.sum}>Цена без скидки:</div>
              <div className={styles.oldPrice}>
                {oldPrice?.toLocaleString('ru-RU')}{' '}
                {getCurrencySymbol(currency)}
              </div>
            </div>
            <div className={styles.sale}>
              <div className={styles.sum}> Скидка:</div>
              <div className={styles.saleSum}>
                <div className={styles.salePrice}>
                  {salePrice?.toLocaleString('ru-RU')}{' '}
                  {getCurrencySymbol(currency)}
                </div>
                {promoRes.discount_percent_status ? (
                  <div
                    className={styles.priceDifference}
                  >{`-${promoRes.discount}%`}</div>
                ) : (
                  <div className={styles.priceDifference}>
                    {`-${salePrice?.toLocaleString('ru-RU')} ${getCurrencySymbol(
                      currency
                    )}`}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ProductsAndPrice
