import { initialState } from '../initialState'
import { GET_PRODUCT } from '../types'

export const actionReducer = (state = initialState(), action) => {
  switch (action.type) {
    case GET_PRODUCT:
      return action.payload.result
    default:
      return state
  }
}
