import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import store from './store/store'
import '@fontsource/manrope'
import React from 'react'
import App from './App'
import './index.css'

Sentry.init({
  dsn: 'https://86a35f0b816b31814f0fcf0bae07851a@sentry.profinansy.ru/8',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
)
