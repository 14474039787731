import { BASE_URL } from './frame'
import axios from 'axios'

const requestUrl = `${BASE_URL}/promocodes/promocode_result/?`

export const getPromoResult = async (
  promocode,
  id,
  checkedProducts,
  payment_method_id
) => {
  try {
    let reqUrl = `${requestUrl}promocode=${promocode}&frame_id=${id}`

    if (checkedProducts) {
      reqUrl += `&${checkedProducts}`
    }
    if (payment_method_id) {
      reqUrl += `&pm_id=${payment_method_id}`
    }

    const res = await axios.get(reqUrl)
    return res
  } catch (e) {
    console.log(e)
  }
}

export const getCountSales = async (email, frame_id) => {
  try {
    const reqUrl = `${BASE_URL}/frames/count_sales_frame_status/?email=${email}&frame_id=${frame_id}`
    const res = await axios.get(reqUrl)
    return res
  } catch (e) {
    console.log(e)
  }
}
