export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case 'USD': {
      return '$'
    }
    case 'JPY': {
      return '¥'
    }
    case 'EUR': {
      return '€'
    }
    case 'RUB': {
      return '₽'
    }
    case 'RUR': {
      return '₽'
    }
    case 'CHF': {
      return '₣'
    }
    case 'GBP': {
      return '£'
    }
    case 'GBX': {
      return 'P£'
    }
    case 'HKD': {
      return 'HK$'
    }
    case 'AED': {
      return 'AED'
    }
    default: {
      return '₽'
    }
  }
}
