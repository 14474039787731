import styles from './Thanks.module.scss'
import React from 'react'

function Fail({ errorCode }) {
  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'
  return (
    <div className={`${styles.modalThanks} ${styles.active}`}>
      <div className={`${styles.modal__contentThanks} ${styles.active}`}>
        {!isIpOrAck && <img src="/logo.png" alt="" className={styles.logoThanks} />}
        <div className={styles.mainThanks}>
          {errorCode !== 401 && (
            <div className={styles.headerThanks}>Ошибка!</div>
          )}
          {errorCode === 401 ? (
            <div className={styles.titleThanks}>
              Ошибка авторизации, пожалуйста войдите в свой аккаунт
              profinansy.ru и повторите попытку
            </div>
          ) : (
            <div className={styles.titleThanks}>
              К сожалению, ваш платеж отклонен.
            </div>
          )}
          <img src="/cancel.png" alt="" className={styles.successLogo} />
          {errorCode !== 401 && (
            <div className={styles.titleThanks}>Повторите попытку позже</div>
          )}

          {!isIpOrAck && (
            <div className={styles.footerThanks}>
              <p className={styles.footerText}>
                Если у Вас не получилось самостоятельно решить проблему,
              </p>
              <a
                href="https://t.me/profinansy_help_bot"
                className={styles.support}
              >
                свяжитесь с нами
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Fail
