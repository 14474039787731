import successMark from '../../icons/successMark.svg'
import styles from './Thanks.module.scss'
import React from 'react'

function CardSuccess() {
  return (
    <div className={`${styles.modalThanks} ${styles.active}`}>
      <div className={`${styles.modal__contentThanks} ${styles.active}`}>
        <div className={styles.mainContent}>
          <div className={styles.mainBox}>
            <img src={successMark} alt="" className={styles.successIcon} />
            <div className={styles.mainText}>Карта успешно привязана</div>
            <div className={styles.subText}>Вы можете продолжить работу</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardSuccess
