import { object, string } from 'yup'

export const InitialValuesPhone = {
  acc_type: 'phone',
  login: ''
}

export const SigninSchemaPhone = object({
  acc_type: string(),
  login: string().required('Login is required')
})

export const InitialValuesEmail = {
  acc_type: 'email',
  login: '',
  pass: ''
}

export const SigninSchemaEmail = object({
  acc_type: string(),
  login: string().required('Login is required'),
  pass: string().required('Password is required')
})
