import styles from './FormButton.module.scss'
import React from 'react'

const Loader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.dot} />
    </div>
  )
}

export default Loader
