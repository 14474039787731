import styles from './FormDescription.module.scss'
import React from 'react'

function FormDescription({
  finsovetnik,
  bindingProduct,
  disEmail,
  disFio,
  disPhone
}) {
  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'

  return (
    <>
      {!bindingProduct ? (
        <div className={styles.title}>
          {finsovetnik ? (
            disEmail || disFio || disPhone ? (
              <>
                <div className={styles.boldTitle}>
                  Ваши данные заполнены с финсоветник.рф
                </div>
                <div>
                  Это сделано для того, чтобы доступ к купленному продукту сразу
                  отобразился в нужном аккаунте.
                </div>
              </>
            ) : (
              <>
                <div>
                  Заполните все данные верно. Мы отправим вам письмо и смс для
                  подтверждения оплаты.
                </div>
                <div className={styles.boldTitle}>
                  Используйте эти данные для входа на финсоветник.рф
                </div>
              </>
            )
          ) : disEmail || disFio || disPhone ? (
            <>
              <div className={styles.boldTitle}>
                Ваши данные заполнены с profinansy.ru
              </div>
              <div>
                Это сделано для того, чтобы доступ к купленному продукту сразу
                отобразился в нужном аккаунте.
              </div>
            </>
          ) : isIpOrAck ? (
            <>
              <div>
                Заполните все данные верно. Мы отправим вам письмо и смс для
                подтверждения оплаты.
              </div>
            </>
          ) : (
            <>
              <div>
                Заполните все данные верно. Мы отправим вам письмо и смс для
                подтверждения оплаты.
              </div>
              <div className={styles.boldTitle}>
                Используйте эти данные для входа на profinansy.ru
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={styles.descriptionFS}>
          Для привязки карты мы спишем 1 рубль и сразу вернем
        </div>
      )}
    </>
  )
}

export default FormDescription
