import { object, string, number } from 'yup'

export const SignupInitialValues = {
  acc_type: 'phone',
  captcha_v2: '',
  login: '',
  pass: '',
  new_user: 0
}

export const SignupSchema = object({
  acc_type: string(),
  captcha_v2: string(),
  login: string().required('Login is required'),
  pass: string().min(6),
  new_user: number()
})
