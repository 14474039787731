import React from 'react'

const Typography = ({ as, color, children, ...restProps }) => {
  const Component = as || 'span'

  const style = color ? { style: { color } } : {}

  return (
    <Component {...restProps} {...style}>
      {children}
    </Component>
  )
}

export default Typography
