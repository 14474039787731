export const ROLE_TABS = [
  {
    id: '01',
    name: 'Клиент',
    tag: 'client',
    selected: true
  },
  {
    id: '02',
    name: 'Финансовый советник',
    tag: 'adviser',
    selected: false
  }
]

export const SIGNUP_TABS = [
  { id: '01', name: 'По телефону' },
  { id: '02', name: 'По e-mail' }
]
