import { getPromoResult } from '../../store/actions/promocode'
import { useLocation, useParams } from 'react-router-dom'
import warning from '../../icons/attention.svg'
import styles from './Promocode.module.scss'
import React, { useState } from 'react'
import queryString from 'query-string'
import { useEffect } from 'react'

function Promocode({
  promoRes,
  setPromoRes,
  btnDisabled,
  checkedProducts,
  promocode,
  setPromocode,
  setPromocode_id,
  setPromoEr,
  payment_method_id
}) {
  const [promoError, setPromoError] = useState(false)
  const [promoSuc, setPromoSuc] = useState(false)
  const [error, setError] = useState('')

  const { id } = useParams()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const getParamsPromo = Array.isArray(query.promocode)
    ? query.promocode?.slice(0, 1).join('').toUpperCase().trim()
    : query.promocode?.toUpperCase().trim()

  useEffect(() => {
    if (getParamsPromo !== undefined) {
      setPromocode(getParamsPromo)
    }
  }, [])

  useEffect(() => {
    setPromocode_id(promoRes.id ?? 0)
    if (promoRes.success) {
      setPromoError(false)
      setPromoSuc(true)
    } else if (promoRes === 'Promocode not found') {
      setError('Промокод не найден')
      setPromoError(true)
      setPromoSuc(false)
    } else if (promoRes === 'Promocode failed') {
      setError('Промокод не действует для данной формы')
      setPromoError(true)
      setPromoSuc(false)
    } else if (promoRes === 'Promocode count ended') {
      setError('Действие промокода закончилось')
      setPromoError(true)
      setPromoSuc(false)
    }
  }, [promoRes])

  async function handlePromo() {
    const res = await getPromoResult(
      promocode,
      id,
      checkedProducts,
      payment_method_id
    )
    setPromoRes(res?.data.result)
  }

  function cancelPromo() {
    setPromocode('')
    setPromoError(false)
    setPromoSuc(false)
    setPromoRes({})
    setPromoEr('')
  }

  return (
    <div className={styles.main}>
      {location.pathname.split('/').slice(1, 2).join('') === 'cart'
        ? ''
        : 'Промокод'}
      <input
        name="promocode"
        className={styles.input}
        type="text"
        placeholder="Введите промокод"
        value={promocode}
        disabled={promoSuc || btnDisabled}
        onChange={(e) => setPromocode(e.target.value.toUpperCase().trim())}
      />
      {promocode &&
        (promoSuc ? (
          <button
            type="button"
            className={
              location.pathname.split('/').slice(1, 2).join('') === 'cart'
                ? styles.promoBtnCart
                : styles.promoBtn
            }
            onClick={() => cancelPromo()}
          >
            Отменить
          </button>
        ) : (
          <button
            type="button"
            className={
              location.pathname.split('/').slice(1, 2).join('') === 'cart'
                ? styles.promoBtnCart
                : styles.promoBtn
            }
            disabled={btnDisabled}
            onClick={() => handlePromo()}
          >
            Активировать
          </button>
        ))}
      {promoError && (
        <div className={styles.promoError}>
          <img src={warning} alt="" className={styles.warning} />
          <span>{error}</span>
        </div>
      )}
      {promoSuc && (
        <div className={styles.promoSuc}>
          <span>🔥 Активирован промокод:</span>
          <div className={styles.promoAct}>{promocode}</div>
        </div>
      )}
    </div>
  )
}

export default Promocode
