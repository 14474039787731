import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Inputs.module.scss'

function NameInput({ name, setName, disFio, setInputsError }) {
  const [nameError, setNameError] = useState('ФИО не может быть пустым')
  const [nameDirty, setNameDirty] = useState(false)

  const location = useLocation()
  const getFio = new URLSearchParams(location.search).get('fio')

  useEffect(() => {
    if (disFio) {
      setName(disFio)
      setNameError('')
      setInputsError((prev) => ({ ...prev, name: false }))
    }
    if (getFio) {
      setName(getFio)
      setNameError('')
      setInputsError((prev) => ({ ...prev, name: false }))
    }
  }, [getFio, disFio])

  function nameHandler(e) {
    setName(e.target.value)
    const re = /[A-Za-zА-Яа-яЁё]/gm
    if (!re.test(String(e.target.value))) {
      setNameError('Некорректное ФИО')
      setInputsError((prev) => ({ ...prev, name: true }))
    } else {
      setNameError('')
      setInputsError((prev) => ({ ...prev, name: false }))
    }
    if (e.target.value?.length === 0) {
      setNameError('ФИО не может быть пустым')
      setInputsError((prev) => ({ ...prev, name: true }))
    }
  }

  function blurHandler(e) {
    if (e.target.name === 'name') {
      setNameDirty(true)
    }
  }

  return (
    <div className={styles.inputBox}>
      <label htmlFor="name">ФИО</label>
      <input
        onChange={(e) => nameHandler(e)}
        value={name}
        onBlur={(e) => blurHandler(e)}
        name="name"
        className={`${styles.input} ${!nameError && styles.inputSuccess}`}
        type="text"
        required
        disabled={disFio}
        placeholder="Иванов Иван Иванович"
      />
      {nameDirty && nameError && (
        <div className={styles.errorText}>{nameError}</div>
      )}
    </div>
  )
}

export default NameInput
