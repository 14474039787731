import LoginViaProfinancy from '../LoginViaProfinancy/LoginViaProfinancy'
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm'
import SignUpForm from '../SignUpFormV2/SignUpForm'
import styles from './LoginForm.module.scss'
import React, { useState } from 'react'

const LoginForm = ({ setLoginModalActive, setUserKey }) => {
  const [type, setType] = useState('login')

  const renderForm = (type) => {
    switch (type) {
      case 'login':
        return (
          <LoginViaProfinancy
            setType={setType}
            setLoginModalActive={setLoginModalActive}
            setUserKey={setUserKey}
          />
        )
      case 'signup':
        return (
          <SignUpForm
            setLoginModalActive={setLoginModalActive}
            setUserKey={setUserKey}
          />
        )
      case 'forgotPass':
        return (
          <ForgotPasswordForm
            setType={setType}
            setLoginModalActive={setLoginModalActive}
            setUserKey={setUserKey}
          />
        )
    }
  }

  return (
    <section className={styles['login-page']}>
      <div className="wrapper">
        <div className={styles.container}>
          <div className={styles['form-container']}>{renderForm(type)}</div>
        </div>
      </div>
    </section>
  )
}

export default LoginForm
