import React from 'react'

const Envelope = () => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50049 31.873C7.50049 18.748 15.0005 13.123 26.2505 13.123H63.7505C75.0005 13.123 82.5005 18.748 82.5005 31.873V58.123C82.5005 71.2481 75.0005 76.8731 63.7505 76.8731H26.2505"
        stroke="#4285F4"
        strokeWidth="5.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.75 33.751L52.0125 43.126C48.15 46.201 41.8125 46.201 37.95 43.126L26.25 33.751"
        stroke="#4285F4"
        strokeWidth="5.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50049 61.875H30.0005"
        stroke="#4285F4"
        strokeWidth="5.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50049 46.874H18.7505"
        stroke="#4285F4"
        strokeWidth="5.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Envelope
