export const getPhoneFormat = (value, setFormat) => {
  switch (value) {
    // каждого case должно быть 16, потому что столько масок
    case '+2__-__-__-__-__':
    case '+2_(___)___-____':
    case '+2__(___)___-__-__':
    case '+2__ __ ___-__-__':
    case '+2__(___)___-___':
    case '+2__-__-___-___':
    case '+2_(___)___-___':
    case '+2_-__-__-__-__':
    case '+2(___)___-__-__':
    case '+2_(___)____-____':
    case '+2_-__-____-____':
    case '+2_-__-____-___':
    case '+2__-___-___-___':
    case '+2__-__-___-__-__':
    case '+2__-___-____':
    case '+2__-____-____':
    case '+30_-__-__-__-__':
    case '+30(___)___-____':
    case '+30_(___)___-__-__':
    case '+30_ __ ___-__-__':
    case '+30_(___)___-___':
    case '+30_-__-___-___':
    case '+30(___)___-___':
    case '+30-__-__-__-__':
    case '+3(0__)___-__-__':
    case '+30(___)____-____':
    case '+30-__-____-____':
    case '+30-__-____-___':
    case '+30_-___-___-___':
    case '+30_-__-___-__-__':
    case '+30_-___-____':
    case '+30_-____-____':
    case '+39_-__-__-__-__':
    case '+39(___)___-____':
    case '+39_(___)___-__-__':
    case '+39_ __ ___-__-__':
    case '+39_(___)___-___':
    case '+39_-__-___-___':
    case '+39(___)___-___':
    case '+39-__-__-__-__':
    case '+3(9__)___-__-__':
    case '+39(___)____-____':
    case '+39-__-____-____':
    case '+39-__-____-___':
    case '+39_-___-___-___':
    case '+39_-__-___-__-__':
    case '+39_-___-____':
    case '+39_-____-____':
    case '+43_-__-__-__-__':
    case '+43(___)___-____':
    case '+43_(___)___-__-__':
    case '+43_ __ ___-__-__':
    case '+43_(___)___-___':
    case '+43_-__-___-___':
    case '+43(___)___-___':
    case '+43-__-__-__-__':
    case '+4(3__)___-__-__':
    case '+43(___)____-____':
    case '+43-__-____-____':
    case '+43-__-____-___':
    case '+43_-___-___-___':
    case '+43_-__-___-__-__':
    case '+43_-___-____':
    case '+43_-____-____':
    case '+44_-__-__-__-__':
    case '+44(___)___-____':
    case '+44_(___)___-__-__':
    case '+44_ __ ___-__-__':
    case '+44_(___)___-___':
    case '+44_-__-___-___':
    case '+44(___)___-___':
    case '+44-__-__-__-__':
    case '+4(4__)___-__-__':
    case '+44(___)____-____':
    case '+44-__-____-____':
    case '+44-__-____-___':
    case '+44_-___-___-___':
    case '+44_-__-___-__-__':
    case '+44_-___-____':
    case '+44_-____-____':
    case '+492-__-__-__-__':
    case '+49(2__)___-____':
    case '+492(___)___-__-__':
    case '+492 __ ___-__-__':
    case '+492(___)___-___':
    case '+492-__-___-___':
    case '+49(2__)___-___':
    case '+49-2_-__-__-__':
    case '+4(92_)___-__-__':
    case '+49(2__)____-____':
    case '+49-2_-____-____':
    case '+49-2_-____-___':
    case '+492-___-___-___':
    case '+492-__-___-__-__':
    case '+492-___-____':
    case '+492-____-____':
    case '+493-__-__-__-__':
    case '+49(3__)___-____':
    case '+493(___)___-__-__':
    case '+493 __ ___-__-__':
    case '+493(___)___-___':
    case '+493-__-___-___':
    case '+49(3__)___-___':
    case '+49-3_-__-__-__':
    case '+4(93_)___-__-__':
    case '+49(3__)____-____':
    case '+49-3_-____-____':
    case '+49-3_-____-___':
    case '+493-___-___-___':
    case '+493-__-___-__-__':
    case '+493-___-____':
    case '+493-____-____':
    case '+494-__-__-__-__':
    case '+49(4__)___-____':
    case '+494(___)___-__-__':
    case '+494 __ ___-__-__':
    case '+494(___)___-___':
    case '+494-__-___-___':
    case '+49(4__)___-___':
    case '+49-4_-__-__-__':
    case '+4(94_)___-__-__':
    case '+49(4__)____-____':
    case '+49-4_-____-____':
    case '+49-4_-____-___':
    case '+494-___-___-___':
    case '+494-__-___-__-__':
    case '+494-___-____':
    case '+494-____-____':
    case '+495-__-__-__-__':
    case '+49(5__)___-____':
    case '+495(___)___-__-__':
    case '+495 __ ___-__-__':
    case '+495(___)___-___':
    case '+495-__-___-___':
    case '+49(5__)___-___':
    case '+49-5_-__-__-__':
    case '+4(95_)___-__-__':
    case '+49(5__)____-____':
    case '+49-5_-____-____':
    case '+49-5_-____-___':
    case '+495-___-___-___':
    case '+495-__-___-__-__':
    case '+495-___-____':
    case '+495-____-____':
    case '+496-__-__-__-__':
    case '+49(6__)___-____':
    case '+496(___)___-__-__':
    case '+496 __ ___-__-__':
    case '+496(___)___-___':
    case '+496-__-___-___':
    case '+49(6__)___-___':
    case '+49-6_-__-__-__':
    case '+4(96_)___-__-__':
    case '+49(6__)____-____':
    case '+49-6_-____-____':
    case '+49-6_-____-___':
    case '+496-___-___-___':
    case '+496-__-___-__-__':
    case '+496-___-____':
    case '+496-____-____':
    case '+497-__-__-__-__':
    case '+49(7__)___-____':
    case '+497(___)___-__-__':
    case '+497 __ ___-__-__':
    case '+497(___)___-___':
    case '+497-__-___-___':
    case '+49(7__)___-___':
    case '+49-7_-__-__-__':
    case '+4(97_)___-__-__':
    case '+49(7__)____-____':
    case '+49-7_-____-____':
    case '+49-7_-____-___':
    case '+497-___-___-___':
    case '+497-__-___-__-__':
    case '+497-___-____':
    case '+497-____-____':
    case '+498-__-__-__-__':
    case '+49(8__)___-____':
    case '+498(___)___-__-__':
    case '+498 __ ___-__-__':
    case '+498(___)___-___':
    case '+498-__-___-___':
    case '+49(8__)___-___':
    case '+49-8_-__-__-__':
    case '+4(98_)___-__-__':
    case '+49(8__)____-____':
    case '+49-8_-____-____':
    case '+49-8_-____-___':
    case '+498-___-___-___':
    case '+498-__-___-__-__':
    case '+498-___-____':
    case '+498-____-____':
    case '+499-__-__-__-__':
    case '+49(9__)___-____':
    case '+499(___)___-__-__':
    case '+499 __ ___-__-__':
    case '+499(___)___-___':
    case '+499-__-___-___':
    case '+49(9__)___-___':
    case '+49-9_-__-__-__':
    case '+4(99_)___-__-__':
    case '+49(9__)____-____':
    case '+49-9_-____-____':
    case '+49-9_-____-___':
    case '+499-___-___-___':
    case '+499-__-___-__-__':
    case '+499-___-____':
    case '+499-____-____':
    case '+410-__-__-__-__':
    case '+41(0__)___-____':
    case '+410(___)___-__-__':
    case '+410 __ ___-__-__':
    case '+410(___)___-___':
    case '+410-__-___-___':
    case '+41(0__)___-___':
    case '+41-0_-__-__-__':
    case '+4(10_)___-__-__':
    case '+41(0__)____-____':
    case '+41-0_-____-____':
    case '+41-0_-____-___':
    case '+410-___-___-___':
    case '+410-__-___-__-__':
    case '+410-___-____':
    case '+410-____-____':
    case '+491-7_-__-__-__':
    case '+49(17_)___-____':
    case '+491(7__)___-__-__':
    case '+491 7_ ___-__-__':
    case '+491(7__)___-___':
    case '+491-7_-___-___':
    case '+49(17_)___-___':
    case '+49-17-__-__-__':
    case '+4(917)___-__-__':
    case '+49(17_)____-____':
    case '+49-17-____-____':
    case '+49-17-____-___':
    case '+491-7__-___-___':
    case '+491-7_-___-__-__':
    case '+491-7__-____':
    case '+491-7___-____':
      setFormat('+##(###)###-####') // 12
      break
    case '+358-__-__-__-__':
    case '+35(8__)___-____':
    case '+358(___)___-__-__':
    case '+358 __ ___-__-__':
    case '+358(___)___-___':
    case '+358-__-___-___':
    case '+35(8__)___-___':
    case '+35-8_-__-__-__':
    case '+3(58_)___-__-__':
    case '+35(8__)____-____':
    case '+35-8_-____-____':
    case '+35-8_-____-___':
    case '+358-___-___-___':
    case '+358-__-___-__-__':
    case '+358-___-____':
    case '+358-____-____':
    case '+491-__-__-__-__':
    case '+49(1__)___-____':
    case '+491(___)___-__-__':
    case '+491 __ ___-__-__':
    case '+491(___)___-___':
    case '+491-__-___-___':
    case '+49(1__)___-___':
    case '+49-1_-__-__-__':
    case '+4(91_)___-__-__':
    case '+49(1__)____-____':
    case '+49-1_-____-____':
    case '+49-1_-____-___':
    case '+491-___-___-___':
    case '+491-__-___-__-__':
    case '+491-___-____':
    case '+491-____-____':
      setFormat('+###(###)###-##-##') // 13
      break
    case '+375-__-__-__-__':
    case '+37(5__)___-____':
    case '+375(___)___-__-__':
    case '+375 __ ___-__-__':
    case '+375(___)___-___':
    case '+375-__-___-___':
    case '+37(5__)___-___':
    case '+37-5_-__-__-__':
    case '+3(75_)___-__-__':
    case '+37(5__)____-____':
    case '+37-5_-____-____':
    case '+37-5_-____-___':
    case '+375-___-___-___':
    case '+375-__-___-__-__':
    case '+375-___-____':
    case '+375-____-____':
    case '+380-__-__-__-__':
    case '+38(0__)___-____':
    case '+380(___)___-__-__':
    case '+380 __ ___-__-__':
    case '+380(___)___-___':
    case '+380-__-___-___':
    case '+38(0__)___-___':
    case '+38-0_-__-__-__':
    case '+3(80_)___-__-__':
    case '+38(0__)____-____':
    case '+38-0_-____-____':
    case '+38-0_-____-___':
    case '+380-___-___-___':
    case '+380-__-___-__-__':
    case '+380-___-____':
    case '+380-____-____':
    case '+351-__-__-__-__':
    case '+35(1__)___-____':
    case '+351(___)___-__-__':
    case '+351 __ ___-__-__':
    case '+351(___)___-___':
    case '+351-__-___-___':
    case '+35(1__)___-___':
    case '+35-1_-__-__-__':
    case '+3(51_)___-__-__':
    case '+35(1__)____-____':
    case '+35-1_-____-____':
    case '+35-1_-____-___':
    case '+351-___-___-___':
    case '+351-__-___-__-__':
    case '+351-___-____':
    case '+351-____-____':
    case '+381-__-__-__-__':
    case '+38(1__)___-____':
    case '+381(___)___-__-__':
    case '+381 __ ___-__-__':
    case '+381(___)___-___':
    case '+381-__-___-___':
    case '+38(1__)___-___':
    case '+38-1_-__-__-__':
    case '+3(81_)___-__-__':
    case '+38(1__)____-____':
    case '+38-1_-____-____':
    case '+38-1_-____-___':
    case '+381-___-___-___':
    case '+381-__-___-__-__':
    case '+381-___-____':
    case '+381-____-____':
    case '+385-__-__-__-__':
    case '+38(5__)___-____':
    case '+385(___)___-__-__':
    case '+385 __ ___-__-__':
    case '+385(___)___-___':
    case '+385-__-___-___':
    case '+38(5__)___-___':
    case '+38-5_-__-__-__':
    case '+3(85_)___-__-__':
    case '+38(5__)____-____':
    case '+38-5_-____-____':
    case '+38-5_-____-___':
    case '+385-___-___-___':
    case '+385-__-___-__-__':
    case '+385-___-____':
    case '+385-____-____':
      setFormat('+### ## ###-##-##')
      break
    case '+359-__-__-__-__':
    case '+35(9__)___-____':
    case '+359(___)___-__-__':
    case '+359 __ ___-__-__':
    case '+359(___)___-___':
    case '+359-__-___-___':
    case '+35(9__)___-___':
    case '+35-9_-__-__-__':
    case '+3(59_)___-__-__':
    case '+35(9__)____-____':
    case '+35-9_-____-____':
    case '+35-9_-____-___':
    case '+359-___-___-___':
    case '+359-__-___-__-__':
    case '+359-___-____':
    case '+359-____-____':
    case '+377-__-__-__-__':
    case '+37(7__)___-____':
    case '+377(___)___-__-__':
    case '+377 __ ___-__-__':
    case '+377(___)___-___':
    case '+377-__-___-___':
    case '+37(7__)___-___':
    case '+37-7_-__-__-__':
    case '+3(77_)___-__-__':
    case '+37(7__)____-____':
    case '+37-7_-____-____':
    case '+37-7_-____-___':
    case '+377-___-___-___':
    case '+377-__-___-__-__':
    case '+377-___-____':
    case '+377-____-____':
    case '+420-__-__-__-__':
    case '+42(0__)___-____':
    case '+420(___)___-__-__':
    case '+420 __ ___-__-__':
    case '+420(___)___-___':
    case '+420-__-___-___':
    case '+42(0__)___-___':
    case '+42-0_-__-__-__':
    case '+4(20_)___-__-__':
    case '+42(0__)____-____':
    case '+42-0_-____-____':
    case '+42-0_-____-___':
    case '+420-___-___-___':
    case '+420-__-___-__-__':
    case '+420-___-____':
    case '+420-____-____':
    case '+5__-__-__-__-__':
    case '+5_(___)___-____':
    case '+5__(___)___-__-__':
    case '+5__ __ ___-__-__':
    case '+5__(___)___-___':
    case '+5__-__-___-___':
    case '+5_(___)___-___':
    case '+5_-__-__-__-__':
    case '+5(___)___-__-__':
    case '+5_(___)____-____':
    case '+5_-__-____-____':
    case '+5_-__-____-___':
    case '+5__-___-___-___':
    case '+5__-__-___-__-__':
    case '+5__-___-____':
    case '+5__-____-____':
      setFormat('+###(###)###-###')
      break
    case '+374-__-__-__-__':
    case '+37(4__)___-____':
    case '+374(___)___-__-__':
    case '+374 __ ___-__-__':
    case '+374(___)___-___':
    case '+374-__-___-___':
    case '+37(4__)___-___':
    case '+37-4_-__-__-__':
    case '+3(74_)___-__-__':
    case '+37(4__)____-____':
    case '+37-4_-____-____':
    case '+37-4_-____-___':
    case '+374-___-___-___':
    case '+374-__-___-__-__':
    case '+374-___-____':
    case '+374-____-____':
    case '+382-__-__-__-__':
    case '+38(2__)___-____':
    case '+382(___)___-__-__':
    case '+382 __ ___-__-__':
    case '+382(___)___-___':
    case '+382-__-___-___':
    case '+38(2__)___-___':
    case '+38-2_-__-__-__':
    case '+3(82_)___-__-__':
    case '+38(2__)____-____':
    case '+38-2_-____-____':
    case '+38-2_-____-___':
    case '+382-___-___-___':
    case '+382-__-___-__-__':
    case '+382-___-____':
    case '+382-____-____':
    case '+386-__-__-__-__':
    case '+38(6__)___-____':
    case '+386(___)___-__-__':
    case '+386 __ ___-__-__':
    case '+386(___)___-___':
    case '+386-__-___-___':
    case '+38(6__)___-___':
    case '+38-6_-__-__-__':
    case '+3(86_)___-__-__':
    case '+38(6__)____-____':
    case '+38-6_-____-____':
    case '+38-6_-____-___':
    case '+386-___-___-___':
    case '+386-__-___-__-__':
    case '+386-___-____':
    case '+386-____-____':
    case '+371-__-__-__-__':
    case '+37(1__)___-____':
    case '+371(___)___-__-__':
    case '+371 __ ___-__-__':
    case '+371(___)___-___':
    case '+371-__-___-___':
    case '+37(1__)___-___':
    case '+37-1_-__-__-__':
    case '+3(71_)___-__-__':
    case '+37(1__)____-____':
    case '+37-1_-____-____':
    case '+37-1_-____-___':
    case '+371-___-___-___':
    case '+371-__-___-__-__':
    case '+371-___-____':
    case '+371-____-____':
    case '+370-__-__-__-__':
    case '+37(0__)___-____':
    case '+370(___)___-__-__':
    case '+370 __ ___-__-__':
    case '+370(___)___-___':
    case '+370-__-___-___':
    case '+37(0__)___-___':
    case '+37-0_-__-__-__':
    case '+3(70_)___-__-__':
    case '+37(0__)____-____':
    case '+37-0_-____-____':
    case '+37-0_-____-___':
    case '+370-___-___-___':
    case '+370-__-___-__-__':
    case '+370-___-____':
    case '+370-____-____':
    case '+373-__-__-__-__':
    case '+37(3__)___-____':
    case '+373(___)___-__-__':
    case '+373 __ ___-__-__':
    case '+373(___)___-___':
    case '+373-__-___-___':
    case '+37(3__)___-___':
    case '+37-3_-__-__-__':
    case '+3(73_)___-__-__':
    case '+37(3__)____-____':
    case '+37-3_-____-____':
    case '+37-3_-____-___':
    case '+373-___-___-___':
    case '+373-__-___-__-__':
    case '+373-___-____':
    case '+373-____-____':
    case '+357-__-__-__-__':
    case '+35(7__)___-____':
    case '+357(___)___-__-__':
    case '+357 __ ___-__-__':
    case '+357(___)___-___':
    case '+357-__-___-___':
    case '+35(7__)___-___':
    case '+35-7_-__-__-__':
    case '+3(57_)___-__-__':
    case '+35(7__)____-____':
    case '+35-7_-____-____':
    case '+35-7_-____-___':
    case '+357-___-___-___':
    case '+357-__-___-__-__':
    case '+357-___-____':
    case '+357-____-____':
    case '+6__-__-__-__-__':
    case '+6_(___)___-____':
    case '+6__(___)___-__-__':
    case '+6__ __ ___-__-__':
    case '+6__(___)___-___':
    case '+6__-__-___-___':
    case '+6_(___)___-___':
    case '+6_-__-__-__-__':
    case '+6(___)___-__-__':
    case '+6_(___)____-____':
    case '+6_-__-____-____':
    case '+6_-__-____-___':
    case '+6__-___-___-___':
    case '+6__-__-___-__-__':
    case '+6__-___-____':
    case '+6__-____-____':
      setFormat('+###-##-###-###')
      break
    case '+31_-__-__-__-__':
    case '+31(___)___-____':
    case '+31_(___)___-__-__':
    case '+31_ __ ___-__-__':
    case '+31_(___)___-___':
    case '+31_-__-___-___':
    case '+31(___)___-___':
    case '+31-__-__-__-__':
    case '+3(1__)___-__-__':
    case '+31(___)____-____':
    case '+31-__-____-____':
    case '+31-__-____-___':
    case '+31_-___-___-___':
    case '+31_-__-___-__-__':
    case '+31_-___-____':
    case '+31_-____-____':
    case '+32_-__-__-__-__':
    case '+32(___)___-____':
    case '+32_(___)___-__-__':
    case '+32_ __ ___-__-__':
    case '+32_(___)___-___':
    case '+32_-__-___-___':
    case '+32(___)___-___':
    case '+32-__-__-__-__':
    case '+3(2__)___-__-__':
    case '+32(___)____-____':
    case '+32-__-____-____':
    case '+32-__-____-___':
    case '+32_-___-___-___':
    case '+32_-__-___-__-__':
    case '+32_-___-____':
    case '+32_-____-____':
    case '+33_-__-__-__-__':
    case '+33(___)___-____':
    case '+33_(___)___-__-__':
    case '+33_ __ ___-__-__':
    case '+33_(___)___-___':
    case '+33_-__-___-___':
    case '+33(___)___-___':
    case '+33-__-__-__-__':
    case '+3(3__)___-__-__':
    case '+33(___)____-____':
    case '+33-__-____-____':
    case '+33-__-____-___':
    case '+33_-___-___-___':
    case '+33_-__-___-__-__':
    case '+33_-___-____':
    case '+33_-____-____':
    case '+34_-__-__-__-__':
    case '+34(___)___-____':
    case '+34_(___)___-__-__':
    case '+34_ __ ___-__-__':
    case '+34_(___)___-___':
    case '+34_-__-___-___':
    case '+34(___)___-___':
    case '+34-__-__-__-__':
    case '+3(4__)___-__-__':
    case '+34(___)____-____':
    case '+34-__-____-____':
    case '+34-__-____-___':
    case '+34_-___-___-___':
    case '+34_-__-___-__-__':
    case '+34_-___-____':
    case '+34_-____-____':
    case '+36_-__-__-__-__':
    case '+36(___)___-____':
    case '+36_(___)___-__-__':
    case '+36_ __ ___-__-__':
    case '+36_(___)___-___':
    case '+36_-__-___-___':
    case '+36(___)___-___':
    case '+36-__-__-__-__':
    case '+3(6__)___-__-__':
    case '+36(___)____-____':
    case '+36-__-____-____':
    case '+36-__-____-___':
    case '+36_-___-___-___':
    case '+36_-__-___-__-__':
    case '+36_-___-____':
    case '+36_-____-____':
    case '+40_-__-__-__-__':
    case '+40(___)___-____':
    case '+40_(___)___-__-__':
    case '+40_ __ ___-__-__':
    case '+40_(___)___-___':
    case '+40_-__-___-___':
    case '+40(___)___-___':
    case '+40-__-__-__-__':
    case '+4(0__)___-__-__':
    case '+40(___)____-____':
    case '+40-__-____-____':
    case '+40-__-____-___':
    case '+40_-___-___-___':
    case '+40_-__-___-__-__':
    case '+40_-___-____':
    case '+40_-____-____':
    case '+41_-__-__-__-__':
    case '+41(___)___-____':
    case '+41_(___)___-__-__':
    case '+41_ __ ___-__-__':
    case '+41_(___)___-___':
    case '+41_-__-___-___':
    case '+41(___)___-___':
    case '+41-__-__-__-__':
    case '+4(1__)___-__-__':
    case '+41(___)____-____':
    case '+41-__-____-____':
    case '+41-__-____-___':
    case '+41_-___-___-___':
    case '+41_-__-___-__-__':
    case '+41_-___-____':
    case '+41_-____-____':
    case '+46_-__-__-__-__':
    case '+46(___)___-____':
    case '+46_(___)___-__-__':
    case '+46_ __ ___-__-__':
    case '+46_(___)___-___':
    case '+46_-__-___-___':
    case '+46(___)___-___':
    case '+46-__-__-__-__':
    case '+4(6__)___-__-__':
    case '+46(___)____-____':
    case '+46-__-____-____':
    case '+46-__-____-___':
    case '+46_-___-___-___':
    case '+46_-__-___-__-__':
    case '+46_-___-____':
    case '+46_-____-____':
    case '+48_-__-__-__-__':
    case '+48(___)___-____':
    case '+48_(___)___-__-__':
    case '+48_ __ ___-__-__':
    case '+48_(___)___-___':
    case '+48_-__-___-___':
    case '+48(___)___-___':
    case '+48-__-__-__-__':
    case '+4(8__)___-__-__':
    case '+48(___)____-____':
    case '+48-__-____-____':
    case '+48-__-____-___':
    case '+48_-___-___-___':
    case '+48_-__-___-__-__':
    case '+48_-___-____':
    case '+48_-____-____':
      setFormat('+##(###)###-###')
      break
    case '+45_-__-__-__-__':
    case '+45(___)___-____':
    case '+45_(___)___-__-__':
    case '+45_ __ ___-__-__':
    case '+45_(___)___-___':
    case '+45_-__-___-___':
    case '+45(___)___-___':
    case '+45-__-__-__-__':
    case '+4(5__)___-__-__':
    case '+45(___)____-____':
    case '+45-__-____-____':
    case '+45-__-____-___':
    case '+45_-___-___-___':
    case '+45_-__-___-__-__':
    case '+45_-___-____':
    case '+45_-____-____':
    case '+47_-__-__-__-__':
    case '+47(___)___-____':
    case '+47_(___)___-__-__':
    case '+47_ __ ___-__-__':
    case '+47_(___)___-___':
    case '+47_-__-___-___':
    case '+47(___)___-___':
    case '+47-__-__-__-__':
    case '+4(7__)___-__-__':
    case '+47(___)____-____':
    case '+47-__-____-____':
    case '+47-__-____-___':
    case '+47_-___-___-___':
    case '+47_-__-___-__-__':
    case '+47_-___-____':
    case '+47_-____-____':
      setFormat('+##-##-##-##-##')
      break
    case '+1__-__-__-__-__':
    case '+1_(___)___-____':
    case '+1__(___)___-__-__':
    case '+1__ __ ___-__-__':
    case '+1__(___)___-___':
    case '+1__-__-___-___':
    case '+1_(___)___-___':
    case '+1_-__-__-__-__':
    case '+1(___)___-__-__':
    case '+1_(___)____-____':
    case '+1_-__-____-____':
    case '+1_-__-____-___':
    case '+1__-___-___-___':
    case '+1__-__-___-__-__':
    case '+1__-___-____':
    case '+1__-____-____':
    case '+7__-__-__-__-__':
    case '+7_(___)___-____':
    case '+7__(___)___-__-__':
    case '+7__ __ ___-__-__':
    case '+7__(___)___-___':
    case '+7__-__-___-___':
    case '+7_(___)___-___':
    case '+7_-__-__-__-__':
    case '+7(___)___-__-__':
    case '+7_(___)____-____':
    case '+7_-__-____-____':
    case '+7_-__-____-___':
    case '+7__-___-___-___':
    case '+7__-__-___-__-__':
    case '+7__-___-____':
    case '+7__-____-____':
      setFormat('+#(###)###-##-##')
      break
    case '+86_-__-__-__-__':
    case '+86(___)___-____':
    case '+86_(___)___-__-__':
    case '+86_ __ ___-__-__':
    case '+86_(___)___-___':
    case '+86_-__-___-___':
    case '+86(___)___-___':
    case '+86-__-__-__-__':
    case '+8(6__)___-__-__':
    case '+86(___)____-____':
    case '+86-__-____-____':
    case '+86-__-____-___':
    case '+86_-___-___-___':
    case '+86_-__-___-__-__':
    case '+86_-___-____':
    case '+86_-____-____':
      setFormat('+##(###)####-####')
      break
    case '+81_-__-__-__-__':
    case '+81(___)___-____':
    case '+81_(___)___-__-__':
    case '+81_ __ ___-__-__':
    case '+81_(___)___-___':
    case '+81_-__-___-___':
    case '+81(___)___-___':
    case '+81-__-__-__-__':
    case '+8(1__)___-__-__':
    case '+81(___)____-____':
    case '+81-__-____-____':
    case '+81-__-____-___':
    case '+81_-___-___-___':
    case '+81_-__-___-__-__':
    case '+81_-___-____':
    case '+81_-____-____':
    case '+8__-__-__-__-__':
    case '+8_(___)___-____':
    case '+8__(___)___-__-__':
    case '+8__ __ ___-__-__':
    case '+8__(___)___-___':
    case '+8__-__-___-___':
    case '+8_(___)___-___':
    case '+8_-__-__-__-__':
    case '+8(___)___-__-__':
    case '+8_(___)____-____':
    case '+8_-__-____-____':
    case '+8_-__-____-___':
    case '+8__-___-___-___':
    case '+8__-__-___-__-__':
    case '+8__-___-____':
    case '+8__-____-____':
      setFormat('+##-##-####-####')
      break
    case '+84_-__-__-__-__':
    case '+84(___)___-____':
    case '+84_(___)___-__-__':
    case '+84_ __ ___-__-__':
    case '+84_(___)___-___':
    case '+84_-__-___-___':
    case '+84(___)___-___':
    case '+84-__-__-__-__':
    case '+8(4__)___-__-__':
    case '+84(___)____-____':
    case '+84-__-____-____':
    case '+84-__-____-___':
    case '+84_-___-___-___':
    case '+84_-__-___-__-__':
    case '+84_-___-____':
    case '+84_-____-____':
      setFormat('+##-##-####-###')
      break
    case '+9__-__-__-__-__':
    case '+9_(___)___-____':
    case '+9__(___)___-__-__':
    case '+9__ __ ___-__-__':
    case '+9__(___)___-___':
    case '+9__-__-___-___':
    case '+9_(___)___-___':
    case '+9_-__-__-__-__':
    case '+9(___)___-__-__':
    case '+9_(___)____-____':
    case '+9_-__-____-____':
    case '+9_-__-____-___':
    case '+9__-___-___-___':
    case '+9__-__-___-__-__':
    case '+9__-___-____':
    case '+9__-____-____':
      setFormat('+###-###-###-###')
      break
    case '+994-__-__-__-__':
    case '+99(4__)___-____':
    case '+994(___)___-__-__':
    case '+994 __ ___-__-__':
    case '+994(___)___-___':
    case '+994-__-___-___':
    case '+99(4__)___-___':
    case '+99-4_-__-__-__':
    case '+9(94_)___-__-__':
    case '+99(4__)____-____':
    case '+99-4_-____-____':
    case '+99-4_-____-___':
    case '+994-___-___-___':
    case '+994-__-___-__-__':
    case '+994-___-____':
    case '+994-____-____':
      setFormat('+###-##-###-##-##')
      break
    case '+354-__-__-__-__':
    case '+35(4__)___-____':
    case '+354(___)___-__-__':
    case '+354 __ ___-__-__':
    case '+354(___)___-___':
    case '+354-__-___-___':
    case '+35(4__)___-___':
    case '+35-4_-__-__-__':
    case '+3(54_)___-__-__':
    case '+35(4__)____-____':
    case '+35-4_-____-____':
    case '+35-4_-____-___':
    case '+354-___-___-___':
    case '+354-__-___-__-__':
    case '+354-____-____':
    case '+354-___-____':
      setFormat('+###-###-####')
      break
    case '+852-__-__-__-__':
    case '+85(2__)___-____':
    case '+852(___)___-__-__':
    case '+852 __ ___-__-__':
    case '+852(___)___-___':
    case '+852-__-___-___':
    case '+85(2__)___-___':
    case '+85-2_-__-__-__':
    case '+8(52_)___-__-__':
    case '+85(2__)____-____':
    case '+85-2_-____-____':
    case '+85-2_-____-___':
    case '+852-___-___-___':
    case '+852-__-___-__-__':
    case '+852-____-____':
    case '+852-___-____':
      setFormat('+###-####-####')
      break
  }
}
