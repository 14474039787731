import styles from './Thanks.module.scss'
import React from 'react'

function Success() {
  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'
  return (
    <div className={`${styles.modalThanks} ${styles.active}`}>
      <div className={`${styles.modal__contentThanks} ${styles.active}`}>
        {!isIpOrAck && <img src="/logo.png" alt="" className={styles.logoThanks} />}
        <div className={styles.mainThanks}>
          <div className={styles.headerThanks}>Спасибо!</div>
          <div className={styles.titleThanks}>Ваш заказ оплачен</div>
          <div className={styles.emailThanks}>
            Письмо-подтверждение отправлено на электронную почту
          </div>
          <img src="/thanks.png" alt="" className={styles.successLogo} />
          <div className={styles.titleThanks}>Проверьте свою почту</div>

          {!isIpOrAck && (
            <div className={styles.footerThanks}>
              <p className={styles.footerText}>Если вы не получили письма,</p>
              <a
                href="https://t.me/profinansy_help_bot"
                className={styles.support}
              >
                свяжитесь с нами
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Success
