import { object, string } from 'yup'

export const InitialValues = {
  acc_type: 'email',
  captcha_v2: '',
  login: ''
}

export const SigninSchema = object({
  acc_type: string(),
  captcha_v2: string(),
  login: string().required('Login is required')
})
